@import "../../styles/theme.scss";

.dataItem {
  display: flex;
  flex-direction: column;
  gap: $space-XS;

  &.inline {
    flex-direction: row;
    justify-content: space-between;
  }

  &.divider {
    gap: $space-S;
  }
}

.alert {
  margin-top: $space-S;
}
