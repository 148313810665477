@import "../../../../../node_modules/@wingspanhq/fe-component-library/dist/theme";

.membership {
  width: 100%;
  border: pxToRem(1) solid $color-gray200;
}
.saveBtn {
  width: 100%;
}

.hideParentModal {
  opacity: 0;
}
