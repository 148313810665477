@import "@wingspanhq/fe-component-library/dist/theme.scss";

.main {
  background-color: $color-gray50;
  padding: $space-XL;
  flex: 1;
}

.icon {
  display: block;
  position: relative;
  top: pxToRem(2);
}

.toolTipContent {
  max-width: pxToRem(260);
  background-color: $color-white;
  box-shadow: $shadow-XL;
  padding: $space-M;

  .arrow {
    border-color: transparent;
  }

  &[x-placement="top"] {
    .arrow {
      border-top-color: $color-white;
    }
  }

  &[x-placement="right"] {
    .arrow {
      border-right-color: $color-white;
    }
  }

  &[x-placement="bottom"] {
    .arrow {
      border-bottom-color: $color-white;
    }
  }

  &[x-placement="left"] {
    .arrow {
      border-left-color: $color-white;
    }
  }
}
