@import "../../../../../node_modules/@wingspanhq/fe-component-library/dist/theme";

.separator {
  height: pxToRem(2);
  background-color: $color-gray200;
}

.memberName {
  flex: 1;
}
