@import "@wingspanhq/fe-component-library/dist/theme.scss";

.bulletedList {
  padding-left: pxToRem(17);

  li {
    margin-bottom: $space-M;
  }

  ul {
    list-style-type: disc;
  }
}
