@import "@wingspanhq/fe-component-library/dist/theme.scss";

.panel {
  &.vertical {
    height: 100%;
  }

  &.active {
    border-color: $color-blue400;
    background-color: $color-blue50;
  }
}
