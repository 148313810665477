@import "@wingspanhq/fe-component-library/dist/theme.scss";

.main {
  background-color: $color-gray50;
  min-height: 100%;
  flex: 1;
}

.enableWithholdingsPanel {
  background-color: $color-amber50;
  border: 0;

  .withholdingsButton {
    background-color: $color-amber400;
    border: 0;
  }
}
